<template>
  <div>
    <ArticleSliderBNG
      :categoryId="categoryId"
      :random="random"
    ></ArticleSliderBNG>
  </div>
</template>
<style scoped lang="scss"></style>
<script>
import ArticleSliderBNG from "@/components/wordpress/ArticleSliderBNG.vue";

export default {
  name: "BaskoNextGenFooter",
  components: {
    ArticleSliderBNG
  },
  props: {
    categoryId: {
      type: Number,
      required: false
    },
    random: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>
