<template>
  <div class="basko-next-gen-grid-wrapper">
    <div v-if="articles && articles.length">
      <v-row class="basko-next-gen-grid">
        <v-col
          :cols="cols"
          :sm="sm"
          :md="md"
          :lg="lg"
          :xl="xl"
          v-for="article in articles"
          :key="article.id"
        >
          <ArticleCardBNG :article="article"></ArticleCardBNG>
        </v-col>
      </v-row>
      <v-pagination
        class="mt-12"
        color="primary"
        v-if="pager && pager.totPages > 1 && !$vuetify.breakpoint.xs"
        :value="pageFilter"
        :page="pager.selPage"
        :length="pager.totPages ? pager.totPages : 0"
        :totalVisible="6"
        @next.stop.prevent="handlePageFilter"
        @prev="handlePageFilter"
        @input="handlePageFilter"
      ></v-pagination>
    </div>
    <div v-else>
      {{ $t("article.noArticleToShow") }}
    </div>
  </div>
</template>
<style scoped lang="scss"></style>
<script>
import Vue from "vue";
import socialSharing from "vue-social-sharing";
import ArticleCardBNG from "@/components/wordpress/ArticleCardBNG.vue";
import CMService from "~/service/cmService";
import { mapGetters } from "vuex";

export default {
  name: "BaskoNextGenFooter",
  props: {
    cols: {
      type: Number,
      default: 12
    },
    sm: {
      type: Number,
      default: 6
    },
    md: {
      type: Number,
      default: 4
    },
    lg: {
      type: Number,
      default: 3
    },
    xl: {
      type: Number,
      default: 3
    },
    categoryId: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      articles: null,
      newFilters: false,
      pager: {},
      noArticleToShow: false,
      search: null
    };
  },
  components: {
    ArticleCardBNG
  },
  methods: {
    async fetchArticles(pageNum) {
      let params = [];
      this.page = pageNum || this.pageFilter;
      this.pager.selPage = this.page;
      params.push({
        filterName: "page",
        filterValue: this.page
      });
      if (this.s != null && this.s.length > 0) {
        params.push({
          filterName: "filter[s]",
          filterValue: this.s
        });
      }

      if (this.cat != null && this.cat.length > 0) {
        params.push({
          filterName: "categories",
          filterValue: this.cat
        });
      }

      if (this.search != null && this.search.length > 0) {
        params.push({
          filterName: "search",
          filterValue: this.search
        });
      } else if (this.categoryId) {
        params.push({
          filterName: "category_basko_next_gen",
          filterValue: this.categoryId
        });
      }
      const res = await CMService.getCustomPostByFilters(
        "basko-next-gen",
        params,
        8
      );

      if (res && res.data && res.data.length) {
        this.pager = res.page;
        this.articles = res.data;
      } else {
        this.articles = null;
      }
    },
    handlePageFilter(page) {
      var _this = this;
      this.$store.dispatch("category/setFilterPage", page);
      this.fetchArticles(page);
      setTimeout(function() {
        _this.$vuetify.goTo(0);
      }, 300);
    }
  },
  computed: {
    ...mapGetters({
      pageFilter: "category/pageFilter"
    })
  },
  created() {
    global.EventBus.$on("searchBNG", async query => {
      this.search = query;
      await this.fetchArticles(1);
    });
  },
  mounted() {
    Vue.use(socialSharing);
    this.fetchArticles(1);
  }
};
</script>
