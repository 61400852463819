<template>
  <v-container
    fluid
    class="basko-next-gen-category-container"
    v-if="categorySlug && category"
  >
    <router-link
      class="d-flex text-decoration-none previous-page black--text mb-4"
      to="/basko-for-next-gen"
    >
      <v-icon class="pr-2 ">$arrowBack</v-icon>
      <span class="h4 text-decoration-underline">{{
        $t("article.goBack")
      }}</span>
    </router-link>
    <v-img
      v-if="category?.banner_image_category?.guid"
      :src="category?.banner_image_category?.guid"
      class="basko-next-gen-category-image rounded-lg d-flex align-center justify-center mb-4"
    >
      <div class="overlay"></div>
      <h1 class="bng-category-title white--text">{{ category.name }}</h1>
      <h2 class="bng-category-subtitle white--text text-h4 font-weight-normal">
        Basko For Next Gen
      </h2>
      <p
        v-if="category.description && category.description.length"
        class="bng-category-description white--text"
      >
        {{ category.description }}
      </p>
    </v-img>
    <div v-else>
      <h1 class="mb-3">{{ category.name }}</h1>
      <p v-if="category.description && category.description.length">
        {{ category.description }}
      </p>
    </div>
    <v-row>
      <v-col cols="12" md="8">
        <ArticleGridBNG
          v-if="category"
          :md="6"
          :lg="6"
          :categoryId="category.id"
        ></ArticleGridBNG>
        <div v-else>
          <p>{{ $t("baskoForNextGen.noArticleToShow") }}</p>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <BaskoNextGenSidebar :categoryId="category.id"></BaskoNextGenSidebar>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <BaskoNextGenFooter :categoryId="category.id"></BaskoNextGenFooter>
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-container>
  <v-container v-else>
    <h3>{{ $t("baskoForNextGen.noCategoryToShow") }}</h3>
  </v-container>
</template>
<style lang="scss">
.basko-next-gen-category-image {
  position: relative;
  .bng-category-title,
  .bng-category-subtitle,
  .bng-category-description {
    position: relative;
    z-index: 2;
    text-align: center;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
}
</style>
<script>
import ArticleGridBNG from "@/components/wordpress/ArticleGridBNG.vue";
import BaskoNextGenSidebar from "@/components/wordpress/BaskoNextGenSidebar.vue";
import BaskoNextGenFooter from "@/components/wordpress/BaskoNextGenFooter.vue";

import CMService from "~/service/cmService";
export default {
  name: "BaskoNextGenCategory",
  components: { ArticleGridBNG, BaskoNextGenSidebar, BaskoNextGenFooter },
  data() {
    return {
      category: null,
      newFilters: false,
      pager: {},
      noArticleToShow: false
    };
  },
  methods: {
    async fetchCategory() {
      const res = await CMService.getCustomPostBySlug(
        "category_basko_next_gen",
        this.categorySlug
      );

      if (res && res.length) {
        this.category = res[0];
      }
    }
  },
  computed: {
    categorySlug() {
      return this.$route.params.categorySlug;
    }
  },
  async created() {
    await this.fetchCategory();
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      console.log("Route changed", to.params.categorySlug);
    }
  }
};
</script>
