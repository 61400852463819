<template>
  <div>
    <div
      v-if="block1 && block1.length > 0"
      class="basko-next-gen-block-1"
      v-html="block1"
    ></div>
    <div
      v-if="block2 && block2.length > 0"
      class="basko-next-gen-block-2"
      v-html="block2"
    ></div>
    <BaskoNextGenSearch class="my-6"></BaskoNextGenSearch>
    <BaskoNextGenPostCategories class="my-6"></BaskoNextGenPostCategories>
    <BaskoNextGenLatestPosts
      :categoryId="categoryId"
      class="my-6"
    ></BaskoNextGenLatestPosts>
    <div
      v-if="block3 && block3.length > 0"
      class="basko-next-gen-block-3"
      v-html="block3"
    ></div>
    <div
      v-if="block4 && block4.length > 0"
      class="basko-next-gen-block-4"
      v-html="block4"
    ></div>
  </div>
</template>
<style scoped lang="scss"></style>
<script>
import BaskoNextGenSearch from "@/components/wordpress/BaskoNextGenSearch.vue";
import BaskoNextGenPostCategories from "@/components/wordpress/BaskoNextGenPostCategories.vue";
import BaskoNextGenLatestPosts from "@/components/wordpress/BaskoNextGenLatestPosts.vue";
import CMService from "~/service/cmService";

export default {
  name: "BaskoNextGenSidebar",
  components: {
    BaskoNextGenSearch,
    BaskoNextGenPostCategories,
    BaskoNextGenLatestPosts
  },
  props: {
    categoryId: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      block1: null,
      block2: null,
      block3: null,
      block4: null
    };
  },
  methods: {
    async fetchBlockData(blockId, blockNumber) {
      try {
        const blockData = await CMService.getUxBlock(blockId);

        if (blockData && blockData.length > 0) {
          this[`block${blockNumber}`] = blockData[0].content.rendered;
        }
      } catch (error) {
        console.error(`Error fetching block ${blockId}:`, error);
      }
    }
  },
  async mounted() {
    try {
      const blockIds = [
        "basko-for-next-gen-block-1",
        "basko-for-next-gen-block-2",
        "basko-for-next-gen-block-3",
        "basko-for-next-gen-block-4"
      ];

      for (let i = 0; i < blockIds.length; i++) {
        await this.fetchBlockData(blockIds[i], i + 1);
      }
    } catch (error) {
      console.error("Error loading blocks:", error);
    }
  }
};
</script>
